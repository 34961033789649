/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText} from '@servicetitan/design-system';
import {ImageBlock} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    pre: "pre",
    h1: "h1",
    a: "a",
    div: "div",
    strong: "strong",
    h2: "h2",
    hr: "hr",
    blockquote: "blockquote",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Color can be any valid for ", React.createElement(_components.code, null, "background-color"), " css property."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#ff745f'}>\n\t{([color, setColor]) => (\n\t\t<div>\n\t\t\t<ColorPicker\n\t\t\t\tcolors={[ '#1fbc70', '#ff745f', '#faafe2', '#8772e5', '#01f346', '#90efc0', '#ffcc80', 'rgb(8, 191, 223)', 'rgb(204, 157, 127)' ]}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t/>\n\t\t\t<BodyText className=\"m-t-2\">Color selected: {color}</BodyText>\n\t\t</div>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "formcolorpicker",
    style: {
      position: "relative"
    }
  }, "Form.ColorPicker", React.createElement(_components.a, {
    href: "#formcolorpicker",
    "aria-label": "formcolorpicker permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Ready to be used inside of ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form"))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#faafe2'}>\n\t{([color, setColor]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"Pick a Color\"\n\t\t\t\tcolors={[ '#1fbc70', '#ff745f', '#faafe2', '#8772e5', '#01f346', 'rgb(255, 145, 22)', 'rgb(144, 239, 192)', 'rgb(255, 204, 128)' ]}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#8772e5'}>\n\t{([color, setColor]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"Pick a Color\"\n\t\t\t\tcolors={[ '#1fbc70', '#ff745f', '#faafe2', '#8772e5', '#01f346', 'rgb(255, 145, 22)', 'rgb(144, 239, 192)', 'rgb(255, 204, 128)' ]}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t\tdescription=\"Description goes here...\"\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "label-help",
    style: {
      position: "relative"
    }
  }, "Label Help", React.createElement(_components.a, {
    href: "#label-help",
    "aria-label": "label help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#01f346'}>\n\t{([color, setColor]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"ColorPicker Label\"\n\t\t\t\tcolors={[ 'rgb(31, 188, 112)', '#ff745f', '#faafe2', '#01f346', '#ff9116', '#90efc0', '#ffcc80', '#08bfdf', '#606162', '#cc9d7f' ]}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t\tlabelProps={{\n\t\t\t\t\thelp: \"This is help text\",\n\t\t\t\t\tdirection: \"r\"\n\t\t\t\t}}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h2, {
    id: "required-and-optional",
    style: {
      position: "relative"
    }
  }, "Required and Optional", React.createElement(_components.a, {
    href: "#required-and-optional",
    "aria-label": "required and optional permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A visual indicator can be applied on a Color Picker's label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={['#01f346', '#01f346']}>\n\t{([colors, setColors]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"ColorPicker Label\"\n\t\t\t\tcolors={[ 'rgb(31, 188, 112)', '#ff745f', '#faafe2', '#01f346', '#ff9116', '#90efc0', '#ffcc80', '#08bfdf', '#606162', '#cc9d7f' ]}\n\t\t\t\tvalue={colors[0]}\n\t\t\t\tonChange={(newColor) => setColors([newColor, colors[1]])}\n\t\t\t\tlabelProps={{\n\t\t\t\t\trequired: true\n\t\t\t\t}}\n\t\t\t/>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"ColorPicker Label\"\n\t\t\t\tcolors={[ 'rgb(31, 188, 112)', '#ff745f', '#faafe2', '#01f346', '#ff9116', '#90efc0', '#ffcc80', '#08bfdf', '#606162', '#cc9d7f' ]}\n\t\t\t\tvalue={colors[1]}\n\t\t\t\tonChange={(newColor) => setColors([colors[0], newColor])}\n\t\t\t\tlabelProps={{\n\t\t\t\t\toptional: true\n\t\t\t\t}}\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "disabled-state",
    style: {
      position: "relative"
    }
  }, "Disabled state", React.createElement(_components.a, {
    href: "#disabled-state",
    "aria-label": "disabled state permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Both ColorPicker and Form.ColorPicker may have disabled state."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#01f346'}>\n\t{([color, setColor]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"Pick a Color\"\n\t\t\t\tcolors={[ 'rgb(31, 188, 112)', '#ff745f', '#faafe2', '#01f346', '#ff9116', '#90efc0', '#ffcc80', '#08bfdf', '#606162', '#cc9d7f' ]}\n\t\t\t\tvalue={'#'}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t\tdisabled\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Both ColorPicker and Form.ColorPicker support ", React.createElement(_components.code, null, "tabIndex"), ", ", React.createElement(_components.code, null, "onFocus"), "/", React.createElement(_components.code, null, "onBlur"), " events when not disabled, and left/right arrow key controls when focused."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most of the content guidelines from ", React.createElement(_components.a, {
    href: "/patterns/forms/#content-guidelines"
  }, "Forms"), " applies to the Color Picker."), "\n", React.createElement(_components.h3, {
    id: "use-a-label-for-all-input-fields",
    style: {
      position: "relative"
    }
  }, "Use a label for all input fields", React.createElement(_components.a, {
    href: "#use-a-label-for-all-input-fields",
    "aria-label": "use a label for all input fields permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A label is a short, meaningful description that clearly indicates what the user is expected to enter. Labels should be 1 to 3 words and written in title case. Labels should primarily be nouns. Avoid using labels as CTAs. A label is not inline help and shouldn’t be used to provide instruction."), "\n", React.createElement(_components.h3, {
    id: "follow-capitalization-rule",
    style: {
      position: "relative"
    }
  }, "Follow capitalization rule", React.createElement(_components.a, {
    href: "#follow-capitalization-rule",
    "aria-label": "follow capitalization rule permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "do"
  }, React.createElement(BodyText, null, "Tag Color"), React.createElement(BodyText, null, "Pick a Color")), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(ImageBlock, {
    suggest: "dont"
  }, React.createElement(BodyText, null, "Tag color"), React.createElement(BodyText, null, "Pick a color")), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h3, {
    id: "show-hints-formatting-and-requirements",
    style: {
      position: "relative"
    }
  }, "Show hints, formatting, and requirements", React.createElement(_components.a, {
    href: "#show-hints-formatting-and-requirements",
    "aria-label": "show hints formatting and requirements permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={'#faafe2'}>\n\t{([color, setColor]) => (\n\t\t<Form>\n\t\t\t<Form.ColorPicker\n\t\t\t\tlabel=\"Tag Color\"\n\t\t\t\tcolors={[ '#1fbc70', '#ff745f', '#faafe2', '#8772e5', '#01f346', 'rgb(255, 145, 22)', 'rgb(144, 239, 192)', 'rgb(255, 204, 128)' ]}\n\t\t\t\tvalue={color}\n\t\t\t\tonChange={(newColor) => setColor(newColor)}\n\t\t\t\tdescription=\"Choose a color to help identify this tag\"\n\t\t\t/>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.h1, {
    id: "related-assets",
    style: {
      position: "relative"
    }
  }, "Related Assets", React.createElement(_components.a, {
    href: "#related-assets",
    "aria-label": "related assets permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "for ColorPicker:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { ColorPicker } from '@servicetitan/design-system';\n")), "\n", React.createElement(_components.p, null, "for Form.ColorPicker:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Form } from '@servicetitan/design-system';\n")), "\n", React.createElement(_components.p, null, "To use color tokens from the design system you need to import them explicitly\n(if ", React.createElement(_components.code, null, "import"), " isn't working please take a look at https://github.com/servicetitan/assets/pull/282, we are working on it currently)"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { tokens } from '@servicetitan/tokens/core';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
